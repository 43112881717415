import {
  Box,
  Card,
  CardContent,
  Paper,
  FormControl,
  Stack,
  Typography,
  Divider,
  Button,
  InputAdornment,
  TextField,
  CircularProgress,
  IconButton,
  InputLabel,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import { Grid, Select, MenuItem } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { theme } from "@/components/Elements/theme";
import storage from "@/utils/storage";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { on } from "events";
import { useNavigate } from "react-router-dom";

interface FormValues {
  currencyTo: string;
  currencyFrom: string;
  amountFrom: string;
  country: string;
  providerCode: string;
}
interface CoinRateItem {
  currency: string;
  paymentMethodId: string;
  image?: string;
}
interface ErrorState {
  currencyTo?: string;
  amountFrom?: any;
  currencyFrom?: string;
  country?: string;
}

interface depositCrypto {
  getDepositAddress: string;
  qrCodeUrl: string;
}

interface ProviderLimits {
  send?: {
    min: number;
    max: number;
  };
}

interface Provider {
  providerCode: string;
  supportedFlows: string[];
  limits?: {
    send?: ProviderLimits;
  };
}

interface FiatCurrencyResponse {
  currency: string;
  currencyURL: string;
}
interface country {
  code: string;
  name: string;
}

interface PaymentMethodOffer {
  amountExpectedTo: string;
  method: string;
  methodName: string;
  rate: string;
  invertedRate: string;
  fee: string;
}

interface Provider {
  providerCode: string;
  rate?: string;
  invertedRate?: string;
  fee?: string;
  amountFrom?: string;
  amountExpectedTo?: string;
  paymentMethodOffer?: PaymentMethodOffer[];

  errorType?: string;
  errorMessage?: string;
  errorDetails?: any;
  logoUrl: string;
}

interface ApiResponse {
  redirectUrl: string;
  orderId: string;
  externalUserId: string;
  externalOrderId: string;
  providerCode: string;
  type: string;
  currencyFrom: string;
  currencyTo: string;
  amountFrom: string;
  country: string;
  state: string | null;
  ip: string | null;
  walletAddress: string;
  walletExtraId: string | null;
  refundAddress: string | null;
  paymentMethod: string;
  userAgent: string | null;
  metadata: string | null;
  createdAt: string;
}

type ProvidersResponse = Provider[];

const useStyles = makeStyles({
  kycContent: {
    maxWidth: "700px",
    color: "white",
    textAlign: "start",
    "& h1": {
      color: "#3C37FF",
      fontSize: "23px",
    },
    "& strong": {
      color: "white",
    },
    "& p, & ul, & ol": {
      color: "white",
    },
    "& ul, & ol": {
      margin: "10px 0",
      paddingLeft: "20px",
    },
    "& ul ul, & ul ol, & ol ul, & ol ol": {
      paddingLeft: "20px",
    },
  },
  sizee: {
    fontSize: "0.8rem",
    lineHeight: "1.6",
  },
});

export const BuyCrypto = () => {
  const classes = useStyles();

  const [error, setError] = useState<ErrorState>({});

  const [loadingCurrency, setLoadingCurrency] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({
    currencyFrom: "",
    currencyTo: "",
    amountFrom: "",
    country: "",
    providerCode: "",
  });

  const [loading, setLoading] = useState(false);

  const [coinRate, setcoinRate] = useState<CoinRateItem[]>([]);
  console.log("coinRate", coinRate);  
  const [fiatCurrency, setFiatCurrencys] = useState<FiatCurrencyResponse[]>([]);
  const [country, setCountry] = useState<country[]>([]);
  const [providersData, setProvidersData] = useState<Provider[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedProviderCode, setSelectedProviderCode] = useState("");
  const [onRampTxn, setOnRampTxn] = useState<ApiResponse>({
    redirectUrl: "",
    orderId: "",
    externalUserId: "",
    externalOrderId: "",
    providerCode: "",
    type: "",
    currencyFrom: "",
    currencyTo: "",
    amountFrom: "",
    country: "",
    state: null,
    ip: null,
    walletAddress: "",
    walletExtraId: null,
    refundAddress: null,
    paymentMethod: "",
    userAgent: null,
    metadata: null,
    createdAt: "",
  });


  const Item = styled(Paper)(({ theme }) => ({
    background: "transparent",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  }));

  var cardStyle = {
    display: "felx",
    transitionDuration: "0.3s",
    background: "#050518",
    boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
    borderRadius: "20px",
  };

  const selectedProvider = providersData.find(
    (provider) => provider.providerCode === selectedProviderCode
  );
  const onRampFunction = async () => {
    setLoading(true); 

    try {
      let token = storage.getToken();

      const res = await axios.post(
        `${process.env.API_URL_PREFIX}/api/v1/user/onRampTxn`,
        formValues,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      setOnRampTxn(res?.data?.createTxn);
      const redirectUrl = res?.data?.createTxn?.redirectUrl;

      console.log(redirectUrl);

      if (redirectUrl) {
        if (redirectUrl.startsWith('/')) {
          const newTab = window.open('', '_blank');
          if (newTab) {
            newTab.location.href = redirectUrl; 
          } else {
            console.error("Popup blocked or failed to open new tab");
          }
        } else {
          const newTab = window.open(redirectUrl, '_blank');
          if (!newTab) {
            console.error("Popup blocked or failed to open new tab");
          }
        }
      } else {
        console.error("No redirect URL found in the response");
      }
    } catch (error) {
      console.error("Error during API call:", error);
    } finally {
      setLoading(false);              
    }
  };

  useEffect(() => {
    const fetchProvidersData = async () => {
      setLoading(true);
      setSelectedProviderCode("");
      try {
        let token = storage.getToken();

        const { providerCode, ...dataToSend } = formValues;

        const res = await axios.post(
          `${process.env.API_URL_PREFIX}/api/v1/user/getProviders`,
          dataToSend,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        setProvidersData(res?.data);
      } catch (error) {
        console.error("Error fetching providers rate:", error);
      } finally {
        setLoading(false);
      }
    };

    const { currencyFrom, currencyTo, amountFrom, country } = formValues;

    const allFieldsFilled =
      currencyFrom !== "" &&
      currencyFrom !== null &&
      currencyTo !== "" &&
      currencyTo !== null &&
      amountFrom !== "" &&
      amountFrom !== null &&
      country !== "" &&
      country !== null;


    if (allFieldsFilled) {
      fetchProvidersData();
    }
  }, [
    formValues.currencyFrom,
    formValues.currencyTo,
    formValues.amountFrom,
    formValues.country,
  ]);

  useEffect(() => {
    const fetchCurrencyData = async () => {
      setLoadingCurrency(true);
      let token = storage.getToken();
      try {
        const currencyResponse = await axios.get(
          `${process.env.API_URL_PREFIX}/api/v1/user/fetchPaymentCurrency`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        const currencyData = currencyResponse?.data?.data;

        setcoinRate(currencyData);
      } catch (error) {
        console.error("Error fetching coin rate:", error);
      } finally {
        setLoadingCurrency(false);
      }
    };

    fetchCurrencyData();
  }, []);

  useEffect(() => {
    const fetchFiatCurrenciesData = async () => {
      setLoadingCurrency(true);
      let token = storage.getToken();
      try {
        const currencyResponse = await axios.get(
          `${process.env.API_URL_PREFIX}/api/v1/user/getFiatCurrencies`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        const currencyData = currencyResponse?.data;

        setFiatCurrencys(currencyData);
      } catch (error) {
        console.error("Error fetching coin rate:", error);
      } finally {
        setLoadingCurrency(false);
      }
    };

    fetchFiatCurrenciesData();
  }, []);

  useEffect(() => {
    const fetchFiatCurrenciesData = async () => {
      setLoadingCurrency(true);
      let token = storage.getToken();
      try {
        const currencyResponse = await axios.get(
          `${process.env.API_URL_PREFIX}/api/v1/user/getCountriesWithCode`,

          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        const currencyData = currencyResponse?.data;

        setCountry(currencyData);
      } catch (error) {
        console.error("Error fetching coin rate:", error);
      } finally {
        setLoadingCurrency(false);
      }
    };

    fetchFiatCurrenciesData();
  }, []);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleProviderClick = (providerCode) => {
    setSelectedProviderCode(providerCode);
    setFormValues((prevState) => ({
      ...prevState,
      providerCode: providerCode,
    }));
    console.log("Selected Provider Code:", providerCode);
  };

  const checkValidation = (type) => {
    let isFormValid = true;
    let newError = { ...error };

    const validationRules = {
      currencyFrom: {
        message: "Please select a fiat currency",
        condition:
          !formValues?.currencyFrom || formValues?.currencyFrom === "select",
      },
      currencyTo: {
        message: "Please select a cryptocurrency",
        condition:
          !formValues?.currencyTo || formValues?.currencyTo === "select",
      },
      country: {
        message: "Please select a country",
        condition: !formValues?.country || formValues?.country === "select",
      },
      amountFrom: {
        message: "Please enter an amount",
        condition: !formValues?.amountFrom,
      },
    };

    const typesToValidate =
      type === "all" ? Object.keys(validationRules) : [type];

    typesToValidate.forEach((key) => {
      if (validationRules[key]?.condition) {
        newError[key] = validationRules[key].message;
        isFormValid = false;
      } else {
        newError[key] = "";
      }
    });

    setError(newError);
    return isFormValid;
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    const fieldsToValidate = [
      "currencyFrom",
      "currencyTo",
      "country",
      "amountFrom",
    ];
    if (fieldsToValidate.includes(name)) {
      checkValidation(name);
    }
  };
  const handleTabChange = (idx: number) => {
    setActiveTab(idx);
  };

  return (
    <Grid
      item
      xs={6}
      md={6}
      sx={{ display: "flex", justifyContent: "center", marginTop: "2%" }}
    >
      {" "}
      <Card
        style={cardStyle}
        sx={{ p: theme.spacing(0.2), m: theme.spacing(0.2) }}
      >
        <CardContent>
          <Stack spacing={1}>
            <Item sx={{ display: "contents" }}>
              {" "}
              <Typography variant="h5">Buy Your Crypto</Typography>
            </Item>
            <Divider sx={{ border: "1px solid rgba(255, 255, 255, 0.2)" }} />

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  mr: 2,
                }}
              >
                <Typography variant="h6" sx={{ display: "block", mt: 2 }}>
                  Country
                </Typography>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    defaultValue="select"
                    name="country"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          bgcolor: "black",
                          "& .MuiMenuItem-root": {
                            padding: 1,
                            fontFamily: "Ubuntu",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "14px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem disabled value="select">
                      <em>Select</em>
                    </MenuItem>

                    {loadingCurrency ? (
                      <MenuItem disabled>
                        <CircularProgress size={24} />
                        <Typography ml={2}>Loading...</Typography>
                      </MenuItem>
                    ) : (
                      country.map((item) => (
                        <MenuItem
                          key={item.name}
                          value={item.code}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                              width: "100%",
                            }}
                          >
                            <Typography
                              textAlign="left"
                              sx={{ fontSize: "20px" }}
                            >
                              {item.name}
                            </Typography>
                          </Box>
                        </MenuItem>
                      ))
                    )}
                  </Select>
                  {error?.country && (
                    <Typography sx={{ color: "red" }}>
                      {error.country}
                    </Typography>
                  )}
                </FormControl>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  mr: 2,
                }}
              >
                <Typography variant="h6" sx={{ display: "block", mt: 2 }}>
                  Fiat Currency
                </Typography>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    defaultValue="select"
                    name="currencyFrom"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          bgcolor: "black",
                          "& .MuiMenuItem-root": {
                            padding: 1,
                            fontFamily: "Ubuntu",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "14px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem disabled value="select">
                      <em>Select</em>
                    </MenuItem>

                    {loadingCurrency ? (
                      <MenuItem disabled>
                        <CircularProgress size={24} />
                        <Typography ml={2}>Loading...</Typography>
                      </MenuItem>
                    ) : (
                      fiatCurrency.map((item) => (
                        <MenuItem
                          key={item.currency}
                          value={item.currency}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                              width: "100%",
                            }}
                          >
                            {item.currencyURL && (
                              <img
                                src={item?.currencyURL}
                                alt={item?.currency}
                                style={{ width: 24 }}
                              />
                            )}
                            <Typography
                              textAlign="left"
                              sx={{ fontSize: "20px" }}
                            >
                              {item.currency}
                            </Typography>
                          </Box>
                        </MenuItem>
                      ))
                    )}
                  </Select>
                  {error?.currencyFrom && (
                    <Typography sx={{ color: "red" }}>
                      {error.currencyFrom}
                    </Typography>
                  )}
                </FormControl>
              </Box>
            </Box>
            <Typography variant="h6" sx={{ display: "block", mt: 2 }}>
              Amount
            </Typography>
            <FormControl fullWidth>
              <TextField
                fullWidth
                name="amountFrom"
                value={formValues.amountFrom}
                id="outlined-multiline-flexible"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {error?.amountFrom && (
                <Typography sx={{ color: "red" }}>
                  {error.amountFrom}
                </Typography>
              )}
            </FormControl>
            <Typography variant="h6" sx={{ display: "block", mt: 2 }}>
              You get (Crypto)
            </Typography>
            <FormControl sx={{ minWidth: 120 }}>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={handleBlur}
                defaultValue="select"
                name="currencyTo"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor: "black",
                      "& .MuiMenuItem-root": {
                        padding: 1,
                        fontFamily: "Ubuntu",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "14px",
                      },
                    },
                  },
                }}
              >
                <MenuItem disabled value="select">
                  <em>Select</em>
                </MenuItem>

                {loadingCurrency ? (
                  <MenuItem disabled>
                    <CircularProgress size={24} />
                    <Typography ml={2}>Loading...</Typography>
                  </MenuItem>
                ) : (
                  coinRate
                    .filter(
                      (item) => item.currency !== "DAI.BEP20" && item.currency !== "USDC.TRC20"
                    )
                    .map((item) => (
                      <MenuItem
                        key={item.paymentMethodId}
                        value={item.currency}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            width: "100%",
                          }}
                        >
                          {item.image && (
                            <img
                              src={item?.image}
                              alt={item.currency}
                              style={{ width: 24 }}
                            />
                          )}
                          <Typography textAlign="left" sx={{ fontSize: "20px" }}>
                            {item.currency}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))
                )}
              </Select>
              {error?.currencyTo && (
                <Typography sx={{ color: "red" }}>
                  {error.currencyTo}
                </Typography>
              )}
            </FormControl>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "80%",
                gap: "10px",
              }}
            >
              {loading ? (
                <CircularProgress
                  size={24}
                  sx={{ justifyContent: "center", marginLeft: "70px" }}
                />
              ) : (
                providersData &&
                Object.values(providersData)
                  ?.filter(
                    (provider) =>
                      provider?.paymentMethodOffer &&
                      provider.paymentMethodOffer[0]?.amountExpectedTo
                  )
                  .map((provider, idx) => (
                    <FormControl
                      key={idx}
                      sx={{ width: "100%", alighnItems: "center" }}
                    >
                      <Button
                        sx={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: "70px",
                          width: "100%",
                          backgroundColor: "#ffa500",
                          textTransform: "none",
                          boxShadow: "none",
                          "&:hover": {
                            backgroundColor: "#ffa500",
                            boxShadow: "none",
                          },
                        }}
                        onClick={() =>
                          handleProviderClick(provider.providerCode)
                        }
                      >
                        <img
                          src={provider.logoUrl}
                          alt={`${provider.providerCode} logo`}
                          style={{ height: "40px", marginRight: "20px" }}
                        />

                        <span
                          style={{
                            flexGrow: 1,
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          {provider.providerCode.toUpperCase()}
                        </span>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span style={{ color: "black", fontSize: "15px" }}>
                            You Get
                          </span>
                          <span>
                            {provider.paymentMethodOffer &&
                            provider.paymentMethodOffer[0]
                              ? `${provider.paymentMethodOffer[0].amountExpectedTo}`
                              : "N/A"}
                          </span>
                        </div>
                      </Button>
                    </FormControl>
                  ))
              )}
            </Box>

            {selectedProviderCode && (
              <CardContent>
                <Typography variant="h6" component="div" gutterBottom>
                  Payment Details
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontSize: "0.8rem" }}>
                      Provider:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      align="right"
                      sx={{ fontSize: "0.8rem" }}
                    >
                      {selectedProvider?.providerCode}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontSize: "0.8rem" }}>
                      Rate:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      align="right"
                      sx={{ fontSize: "0.8rem" }}
                    >
                      {selectedProvider?.rate}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontSize: "0.8rem" }}>
                      Fee:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      align="right"
                      sx={{ fontSize: "0.8rem" }}
                    >
                      {selectedProvider?.fee}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontSize: "0.8rem" }}>
                      <strong>Total (Including Fee):</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      align="right"
                      sx={{ fontSize: "0.8rem" }}
                    >
                      <strong>{selectedProvider?.amountFrom}</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ fontSize: "0.8rem" }}>
                      You Receive:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      align="right"
                      sx={{ fontSize: "0.8rem" }}
                    >
                      {selectedProvider?.amountExpectedTo}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            )}

{providersData && selectedProviderCode && (
  <Button
    sx={{
      marginTop: "50px !important",
      backgroundColor: "#4CAF50",
      color: "white",
      textTransform: "none",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "#45a049",
        boxShadow: "none",
      },
    }}
    onClick={() => onRampFunction()} // API call here
    disabled={loading} // Disable while loading
  >
    {loading ? 'Processing...' : `Pay via ${selectedProviderCode.toUpperCase()}`}
  </Button>
)}


            <Box sx={{ p: { xs: "34px", md: "50px" } }}>
              <Grid item xs={12}>
                <Card sx={{ margin: "0 auto", background: "none" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      maxWidth: "920px",

                      margin: "auto",
                      overflow: "auto",
                    }}
                  >
                    <div className={classes.kycContent}>
                      <p className={classes.sizee}>Please note!:</p>

                      <p className={classes.sizee}>
                        Buy Crypto easy and quick with Changelly. Use the
                        correct deposit address that matches the chosen token to
                        top-up your wallet immediately.
                        <p>Coin/token : Receiving </p>
                        <p>
                          Address USDT.ERC20 :
                          0xa17C7c6635e5ef931Ce00819d4C2aFdef9A6f450
                        </p>
                        <p>
                          {" "}
                          USDT.BEP20 :
                          0x7AcBb142309D01A84170Cc3bC0141AeA2C9fCc2F
                        </p>
                        <p>
                          {" "}
                          ETH.ERC20 : 0xa17C7c6635e5ef931Ce00819d4C2aFdef9A6f450
                        </p>
                        <p> BTC : 3EMmGk1vccKtVyZxJuaffy4nxqERDQGr8K</p>
                      </p>
                    </div>
                  </div>
                </Card>
              </Grid>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
};
