import * as React from "react";
import * as z from "zod";
import { useAuth } from "@/lib/auth";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { loginWithEmailAndPassword } from "../api/loginAuth";
import {
  TextField,
  FormControlLabel,
  Paper,
  Checkbox,
  CssBaseline,
  Link,
  Box,
  Typography,
  Button,
  Grid,
  FormControl,
  InputLabel,
  useTheme,
  Stack,
  Avatar,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { CrouselComponent } from "@/components/Elements/crousel";
import logoCrypto from '@/assets/logoCrypto.svg';
import inActiveEye from "@/assets/inActiveEye.svg";
import blueEye from "@/assets/blueEye.svg";
import inActiveMail from "@/assets/InActiveMail.png";
import inActiveOpenEye from "@/assets/inActiveOpenEye.svg";
import inActiveBlueEye from "@/assets/inActiveBlueEye.svg";
import activeMail from "@/assets/activeMail.svg";
import waving from "@/assets/waving.svg";
import { makeStyles } from '@material-ui/core/styles';
import { Navigate, useSearchParams } from "react-router-dom";
import storage from "@/utils/storage";

import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

const formSchema = z
  .object({
    
    email: z.string().min(1, "Email is required"),
    password: z
      .string()
      .min(1, "Password is required")
      .min(8, "Password must have more than 8 characters")
  })

type LoginValues = {
  email: string;
  password: string;
  isChecked: boolean;
};

const initialValues: LoginValues = {
  email: "",
  password: "",
  isChecked: false,
};
type FormSchemaType = z.infer<typeof formSchema>;

type LoginFormProps = {
  onSuccess: (email: any) => void;
};

const useStyles = makeStyles(theme => ({
  customTextField: {
    "& input::placeholder": {
      fontSize: "14px"
    }
  }
}));

export const LoginForm = ({ onSuccess }: LoginFormProps) => {
  // const { login, isLoggingIn } = useAuth();
  const classes = useStyles();
  const [errors, setErrors] = useState<z.ZodIssue[]>([]);
  const theme = useTheme();
  const [Icon, setIcon] = React.useState(activeMail);
  const [passwordIcon, setPasswordIcon] = React.useState(inActiveEye);
  const [searchParams] = useSearchParams();
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  console.log(searchParams.get('query'));
  const {
    register,
    handleSubmit,
    formState: {  isSubmitting },
  } = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
  });

  const [formValues, setFormValues] = useState<LoginValues>(initialValues);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isOpen, setisOpen] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const remeberMe = storage.getRemeberMe();
    const userName = storage.getUserName();
    if (remeberMe && userName) { 
      let emailValue = storage.getUserName();
      let passwordValue = storage.getPassword();
      let dataValue = {
        email: emailValue,
        password: passwordValue,
        isChecked:true
      };
      storage.setUserNameAndPassword(dataValue);
      setFormValues(dataValue)
      setIsChecked(true);
    }
  }, []);

  const handleRemeberMe = () => {
    const isCheckedValue = isChecked;
    let handleChecked;
    if(isCheckedValue === false){
      setIsChecked(true);
      handleChecked = true;
    }
    else if(isCheckedValue === true ) {
      setIsChecked(false);
      handleChecked = false;
    }
    let rememberMeValue = storage.getRemeberMe();
    if (!handleChecked) {
      storage.cleartUserNameAndPassword();
      storage.clearRemeberMe()
    } else if (handleChecked) {
      storage.setUserNameAndPassword(formValues);
      storage.setCheckBox(true);
    }
  };

  const handlePassword = () => {
    setShowPassword(!showPassword);
    if(showPassword === true)
    setPasswordIcon(inActiveBlueEye)
    else
      setPasswordIcon(blueEye)

  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const result = formSchema.safeParse(formValues);
    let {email, password} = formValues;
    if (result.success) {
      const { email: email, password: password } = result.data;
      onSubmit(result.data);
    } else {
      setErrors(result.error.issues);
    }
  };

  const onSubmit = async (data) => {
    setIsFormSubmitting(true);
    if (isChecked && formValues.email !== "") {
      let dataValues = {
        email: formValues.email,
        password: formValues.password,
      };
      storage.setUserNameAndPassword(dataValues);
      storage.setCheckBox(isChecked);
    }
    try {
      // await login(formValues);
      const response =await loginWithEmailAndPassword(data);
      onSuccess(formValues.email);
      setisOpen(true);
    } catch (err:any) {
      const message = err.response?.data?.message || err.message;
    }finally {
      setIsFormSubmitting(false); // Stop the loader
    }
  };

  return (
    <>
      <Box className="mervlogo" sx={{ paddingRight: "10px", paddingTop: "14px" ,display:{xs:'none', md:'block', sm:'none'} }}>
              <Link variant="h3" href="/"
              >
                <img src={logoCrypto} className="logoImage" /></Link>
            </Box>
      <Typography component="h1" variant="h1" sx={{ mt: 1 }} >
        Welcome <img src={waving} alt="waving" />
      </Typography>
      <Typography component="h6" variant="h6">
        Please enter your credentials to sign in.
      </Typography>
      <Box sx={{ mt: 3.25 }}>
        <form onSubmit={handleFormSubmit}>
          <Grid container>
            <Grid item xs={6} sm={12} md={12} sx={{ pt: {md:2} }}>
              <FormControl sx={{ width:'calc(100% - 50px)'}}>
                <InputLabel
                  htmlFor="email"
                  shrink={true}
                  sx={{ color: "#FFFFFF" }}
                >
                  Email Address
                </InputLabel>
                <TextField
                 sx={{fontSize:{xs:"14px !important", sm:"14px !important", md:"14px !important"},
                }}
                  margin="normal"
                  required
                  classes={{ root: classes.customTextField }}
                  id="email"
                  {...register("email")}
                  autoFocus
                  autoComplete="email"
                  onChange={handleChange}
                  value={formValues.email}
                  placeholder="Enter your Mail Id"
                  onFocus={(e) => {
                    setIcon(activeMail);
                  }}
                  onBlur={(e) => {
                    setIcon(inActiveMail);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment disableTypography position="end">
                        <img src={Icon} alt="inactiveEye" />
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.find((err) => err.path[0] === 'email') && (
        <div style={{ color: '#FF0000', fontSize:"8px" }}>{errors.find((err) => err.path[0] === 'email')?.message}</div>
      )}
                
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={12} md={12} sx={{ pt: {md:2} }}>
              <FormControl  sx={{ width:'calc(100% - 50px)'}}>
                <InputLabel
                  htmlFor="password"
                  shrink={true}
                  sx={{ color: "#FFFFFF" }}
                >
                  Password
                </InputLabel>
                <TextField
                  margin="normal"
                  type={showPassword ? "text" : "password"}
                  required
                  classes={{ root: classes.customTextField }}
                  fullWidth
                  id="password"
                  {...register("password")}
                  onChange={handleChange}
                  autoComplete="password"
                  value={formValues.password}
                  placeholder="Enter your Password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment disableTypography position="end" onClick={handlePassword}>
                        <img src={passwordIcon} alt="inactiveEye" />
                      </InputAdornment>
                    ),
                  }}
                  onFocus={(e) => {
                    setPasswordIcon(inActiveBlueEye);
                  }}
                  onBlur={(e) => {
                    setPasswordIcon(inActiveEye);
                    // setPasswordIcon(inActiveEye);
                  }}
                />
                {errors.find((err) => err.path[0] === 'password') && (
        <div style={{ color: '#FF0000', fontSize:"14px" }}>{errors.find((err) => err.path[0] === 'password')?.message}</div>
      )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            {/* <Grid item xs sx={{ fontSize: "14px" }}>
              <Checkbox
                value={isChecked}
                checked={isChecked}
                disableFocusRipple={true}
                onChange={handleRemeberMe}
                disableTouchRipple={true}
                sx={{ p: 0, fontSize: "14px" }}
                icon={<CheckBoxOutlineBlankIcon htmlColor="#FFFEEC99" />}
                name="lsRememberMe"
                checkedIcon={<CheckBoxIcon htmlColor="#3C37FF" />}
              />
              Remember me
            </Grid> */}
            <Grid
              item
              xs
              sx={{
                justifyContent: "end",
                display: "flex",
                paddingRight: 'calc(100% - 90%)'
              }}
            >
              <Link
                href="/auth/forgetPassword"
                variant="h3"
                color="#3C37FF"
                underline="none"
              >
                Forgot your password?
              </Link>
            </Grid>
          </Grid>
          {isFormSubmitting ? (
    <CircularProgress size={24} color="inherit" /> // Show the loader
  ) : (
    <Button
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            sx={{ mt:{sm:1, md:3}, mb: 2 }}
          >
            Sign In
          </Button>
  )}
          
        </form>
        <Grid container>
          <Grid item xs>
            <Typography variant="h3">
              Don't have an account?{" "}
              <Link
                href="/auth/register"
                variant="h3"
                underline="none"
                sx={{ pl: "4px" }}
              >
                Sign Up
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
